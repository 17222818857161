.login {
  position: relative;
}

.login__link {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16px;
  color: $white-color;
  background-color: $brand-color;
  font-family: $font-family;
  font-weight: $bold-font-weight;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-decoration: none;
  padding: 8px 15px 10px 35px;

  &:hover {
    text-decoration: none;
    color: $white-color;
    background-color: shade($brand-color, 20%);
  }

  &:before {
    content: '';
    background: url('../images/docs-icon.svg') no-repeat bottom center;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 10px;
    top: 9px;
  }

  &.klant{
    right: 150px;
    &:before {
    content: '';
    background-image: url('../images/logo_customer-zone.svg');
    }
  } 

}
