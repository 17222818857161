.banner {
  @media (min-width: 1280px) {
    position: relative;

    &::before {
      content: '';
      display: block;

      background: url('../images/fidufix-icon-green.png') no-repeat;
      background: url(''),
        linear-gradient(transparent, transparent);

      mask: url('../images/fidufix-icon.svg') no-repeat;
      background-color: $brand-color;
      

      position: absolute;
      top: 50%;
      z-index: 10;
      width: 100px;
      height: 100px;
      left: -45px;
      margin-top: -52px;
    }
  }

  @media (min-width: 1500px) {
    &::before {
      width: 222px;
      height: 222px;
      left: -60px;
      margin-top: -88px;
    }
  }
}

.banner__main {
  padding-top: 60px;
}


