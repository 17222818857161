@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

$enable-flex: false;

// Colors
$white-color: 			#fff;
$black-color: 			#000;
$base-color: 			#222626;
$brand-color: 			#7fbe38;
$light-brand-color: 	#a6b990;
$dark-brand-color: 		#5e8c29;
$light-grey-color: 		#6c6d70;
$grey-color: 			#39383a;


$font-family: 'Open Sans', sans-serif;
$bold-font-weight: 600;
$medium-font-weight: 600;

// Bootstrap
$font-family-base: $font-family;
$line-height-base: 1.25;
$body-color: $base-color;
