.nav-footer {
  
}

.nav-footer__list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin: 0 0 0 10px;
    padding: 0;
    display: inline-block;

    &:first-child {
      margin-left: 0;
    }
  }

  a {
    display: block;
    font-size: 18px;
    line-height: 20px;
    font-family: $font-family;
    font-weight: $medium-font-weight;
    color: $white-color;
    text-decoration: none;
  }
}


@media (min-width: 768px) {

  .nav-footer {
    text-align: right;
  }

}

@media (min-width: 992px) {

.nav-footer__list li{
    margin: 0 0 0 20px;
  }

  }